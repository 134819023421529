<ng-container *ngIf="imageData">
  <div #imageWrapper class="acf-image__sizer" [ngStyle]="{'padding-bottom': (imageAspectRatio) * 100 + '%' }">
    <div class="acf-image" *ngIf="!lazyLoadingDisabled" [ngClass]="{'acf-image__loading' : !imageLoaded}">
      <img class="acf-image__image"
           [width]="imageData.width"
           [height]="imageData.height"

           [sizes]="imageViewportSize"
           [lazyLoad]="imageData.srcSet"

           [useSrcset]="true"

           [alt]="imageData.alt"
           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

           [offset]="offsetLazyLoading"

           (onStateChange)="handleImgStateChanged($event)"
      >
    </div>
    <div class="acf-image" *ngIf="lazyLoadingDisabled">
      <img class="acf-image__image"
           *ngIf="imageType !== 'gif' && imageType !== 'svg'"
           [width]="imageData.width"
           [height]="imageData.height"

           [sizes]="imageViewportSize"
           [srcset]="imageData.srcSet"

           [alt]="imageData.alt"
           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      >
      <img class="acf-image__image"
           *ngIf="imageType === 'gif' || imageType == 'svg'"
           [width]="imageData.width"
           [height]="imageData.height"
           [alt]="imageData.alt"
           [src]="imageData.src"
      >
    </div>
  </div>
</ng-container>

