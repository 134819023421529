import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from './store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {getClosedAPINotificationIDs, getIntroWatched, getRouterAnimationDirection} from './store/selectors/user.selectors';
import {
  addClosedAPINotificationIDs,
  setActiveTab,
  setIntroWatched,
  setPreviousUrl,
  setRouterAnimationDirection
} from './store/actions/user.actions';
import {NavigationTabs} from './enums/navigation-tabs';
import {routerAnimations} from './animations';
import {NavigationEnd, NavigationStart, Router, RouterOutlet} from '@angular/router';
import {RouterAnimationDirections} from './enums/router-animation-directions';
import {ScrollService} from './services/scroll.service';
import {OpeningHoursService} from './services/opening-hours.service';
import {GlobalOptionsService} from './services/global-options.service';
import {HintsService} from './services/hints.service';
import {setSingleHintContent, showSingleHint} from './store/actions/notification.actions';
import {ContentService} from './services/content.service';
import {take} from 'rxjs/operators';
import {NotificationsService} from './services/notifications.service';


declare let _paq: any;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'mak-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerAnimations
  ]
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('routerWrapperNode') routerWrapperNode!: ElementRef;

  navigationTabs = NavigationTabs;

  animationState = RouterAnimationDirections.NONE;
  animationDirection = RouterAnimationDirections.NONE;
  i = 0;

  iosVersion: number | undefined = undefined;
  currentUrl = '';

  private readonly DESKTOP_BREAKPOINT = 768;
  isDesktopComponentLoaded = false;

  showTurnDeviceOverlay = false;

  @ViewChild('desktopPageContainer', { read: ViewContainerRef }) desktopPageContainer!: ViewContainerRef;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(): void {
    this.scrollService.updateScrollPosition(document.getElementsByTagName('html')[0].scrollTop);
  }

  @HostListener('window:resize', ['$event'])
  checkDesktop(event: any): void {
    if (event.target.innerWidth >= this.DESKTOP_BREAKPOINT){
      this.loadDesktopPage();
      this.toggleBodyScroll(false);
    } else {
      this.toggleBodyScroll(true);
      this.handleDeviceOrientation();
    }
  }

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private cdref: ChangeDetectorRef,
    private scrollService: ScrollService,
    private openingHoursService: OpeningHoursService,
    private globalOptionsService: GlobalOptionsService,
    private content: ContentService,
    private hintsService: HintsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private notificationsService: NotificationsService
  ) {
  }

  ngOnInit(): void {

    // Intro Help Hints
    setTimeout(() => {
      this.store.select(getIntroWatched).pipe(take(1)).subscribe((response) => {
        if (!response) {
          this.globalOptionsService.getGlobalOptions().then((globalOptions) => {
            this.hintsService.startHintsTour(globalOptions.introTourCards);
            this.store.dispatch(setIntroWatched({watched: true}));
          });
        }
      });
    }, 1500);

    // Hint before close
    setInterval(() => {
      this.globalOptionsService.getGlobalOptions().then((response) => {
        if (!response) {
          return;
        }
        const isShowHintBeforeClose = this.openingHoursService.isNMinutesBeforeClose(15, response.openingHours);
        if (isShowHintBeforeClose) {
          this.store.dispatch(setSingleHintContent({hint: response.beforeCloseHint}));
          this.store.dispatch(showSingleHint({timestamp: `${Date.now()}`}));
        }
      }).catch(e => {
        console.error(e);
      });
    }, 59000); // approx. every minute: check whether in 15 minutes closing or not


    // IOS Version check (css classes)
    this.iosVersion = this.getIOSVersion();

    if (this.iosVersion !== undefined) {
      const bodyNode = document.getElementsByTagName('body')[0];

      if (this.iosVersion < 15) {
        bodyNode.classList.add('ios');
      } else {
        bodyNode.classList.add('ios-new');
      }
    }

    // Scroll Observable
    this.scrollService
      .setScrollPosition()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        document.getElementsByTagName('html')[0].scrollTop = response;
    });

    this.store.select(getRouterAnimationDirection)
      .pipe(untilDestroyed(this))
      .subscribe(response => {
        this.animationDirection = response;
      });

    this.handleRouteChange();

    // Set active main nav tab on page Load
    const location = window.location.pathname;
    console.log(location);

    if (location.indexOf('audio') > -1 ||
      location.indexOf('tour') > -1) {
      this.store.dispatch(setActiveTab({tab: NavigationTabs.AUDIO}));
    } else if (location.indexOf('code') > -1) {
      this.store.dispatch(setActiveTab({tab: NavigationTabs.CODE}));
    } else if (location.indexOf('locations') > -1) {
      this.store.dispatch(setActiveTab({tab: NavigationTabs.LOCATION}));
    } else if (location === '/') {
      this.store.dispatch(setActiveTab({tab: NavigationTabs.HOME}));
    } else {
      this.store.dispatch(setActiveTab({tab: NavigationTabs.NONE}));
    }

    this.getTodaysNotifications();

  }

  // @ts-ignore
  ngAfterViewInit(): void {
    if (window.innerWidth >= this.DESKTOP_BREAKPOINT){
      this.loadDesktopPage();
      this.toggleBodyScroll(false);
    } else {
      this.handleDeviceOrientation();
    }


  }

  prepareRoute(outlet: RouterOutlet): string {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData.lang;
  }

  onActivate(e: any): void {
    // window.scroll(0, 0);
  }

  getIOSVersion(): number | undefined {
    const match = (navigator.userAgent).match(/OS (\d)?\d_\d(_\d)?/i);
    console.log(match);
    if (match && match.length) {
      const version = match[0].split('_')[0].split(' ')[1];
      return parseInt(version, undefined);
    }
    return undefined;
  }

  handleRouteChange(): void {
    // Handle Router Animations here
    this.currentUrl = this.router.url;
    this.router.events
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.animationState = RouterAnimationDirections.NONE;
        } else if (event instanceof NavigationEnd) {
          this.animationState = this.animationDirection;
          this.store.dispatch(setPreviousUrl({url: this.currentUrl}));
          this.currentUrl = event.url;

          setTimeout(() => {
            window.scroll(0, 0);
          }, 250);

          setTimeout(() => {
            this.store.dispatch(setRouterAnimationDirection({direction: RouterAnimationDirections.NONE}));
            this.animationState = RouterAnimationDirections.NONE;
          }, 300);

          // Matomo Analytics Push Page Change
          _paq.push(['setCustomUrl', event.urlAfterRedirects]);
          _paq.push(['setDocumentTitle', 'Mak Guide']);
          _paq.push(['trackPageView']);

        }
      });
  }


  getTodaysNotifications(): void {
    // setInterval(() => {
    this.content.getTodaysNotifications() // get all notifications for today
      .pipe(untilDestroyed(this))
      .subscribe((newNotifications) => {
        this.store.select(getClosedAPINotificationIDs)
          .pipe(take(1))
          .subscribe((notificationIDs) => { // get all notifications which already seen
          const notificationsToShow = newNotifications.filter((el) => {
            return notificationIDs.indexOf(el.id ?? -1) === -1;
          }); // filter today notification without seen notification
          console.log('New notifications:');
          console.log(notificationsToShow);

          notificationsToShow.forEach((localNotification, idx) => { // show all new notifications in 2 seconds after each other
            setTimeout(() => {
              this.store.dispatch(addClosedAPINotificationIDs(
                {ids: [localNotification.id ?? -1]})
              ); // add it to notifications that already seen
              this.notificationsService.pushNotification(localNotification); // push notification
            }, (1000 * 60 * 5) + (5000 * idx)); // show first one in 5 minutes, then in 5 minutes + 5*x seconds
          });
        });
      });
    // }, 1000 * 60 * 5);
  }

  toggleBodyScroll(state: boolean): void {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflow = (state) ? '' : 'hidden';
      body.style.height = (state) ? '' : '100vh';
    }
  }

  async loadDesktopPage(): Promise<any> {
    if (!this.desktopPageContainer) {
      return;
    }

    if (this.isDesktopComponentLoaded) {
      return;
    }

    this.isDesktopComponentLoaded = true;

    const { LandingComponent } = await import('./landing/landing.component');
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LandingComponent);
    const { instance } = this.desktopPageContainer.createComponent(componentFactory);
  }

  handleDeviceOrientation(): void {

    const height = document.documentElement.offsetHeight ? document.documentElement.offsetHeight : window.innerHeight;
    const width = document.documentElement.offsetWidth ? document.documentElement.offsetWidth : window.innerWidth;

    if (width > 992 || width < height) {
      this.showTurnDeviceOverlay = false;
    } else {
      this.showTurnDeviceOverlay = true;
    }

  }

}
