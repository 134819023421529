import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {AcfImage} from '../../interfaces/acf-image';
import {StateChange} from 'ng-lazyload-image';

@Component({
  selector: 'mak-acf-image',
  templateUrl: './acf-image.component.html',
  styleUrls: ['./acf-image.component.scss']
})
export class AcfImageComponent implements OnInit, AfterViewInit {

  @Input('image-data') set setItems(value: any) {
    if (value && typeof value !== 'undefined') {
      this.imageData = Object.assign({}, value);
      if (this.imageData.src.indexOf('.svg') !== -1) {
        this.lazyLoadingDisabled = true;
        this.imageType = 'svg';
        this.cdref.detectChanges();
      } else if (this.imageData.src.indexOf('gif') !== -1) {
        this.imageType = 'gif';
      } else {
        this.imageType = 'normal';
      }
      this.getImageAspectRatio();
    }
  }

  @Input('disable-lazy-loading') set setDisableLazyLoading(value: boolean) {
    if (value !== undefined && value !== null) {
      this.lazyLoadingDisabled = value;
    }
  }

  @Input('force-ratio') set setForceRatio(value: string) {
    if (typeof value !== 'undefined') {

      switch (value) {
        case 'square':
          this.forceSquare = true;
          break;
        case 'fourBySeven':
          this.forceFourBySeven = true;
          break;
        default:
          console.warn('Unknown Format in AcfImage/setForceRatio: ' + value);
      }

      this.getImageAspectRatio();
    }
  }

  @Output() imageLoadedDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('imageWrapper') imageWrapperElement!: ElementRef;

  imageData: AcfImage = {
    alt: '', caption: '', height: 0, src: '', srcSet: '', title: '', width: 0
  };

  imageAspectRatio = 1.0;

  imageLoaded = false;
  offsetLazyLoading = 300;
  imageViewportSize = '150px';

  lazyLoadingDisabled = false;

  forceSquare = false;
  forceFourBySeven = false;
  imageType: 'normal' | 'svg' | 'gif' = 'normal';

  constructor(
    private cdref: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.offsetLazyLoading = window.innerHeight * 2;
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.imageViewportSize = this.imageWrapperElement.nativeElement.offsetWidth + 'px';
    this.cdref.detectChanges();
  }

  handleImgStateChanged(event: StateChange): void {

    switch (event.reason) {
      case 'setup':
        // The lib has been instantiated but we have not done anything yet.
        break;
      case 'observer-emit':
        // The image observer (intersection/scroll/custom observer) has emit a value so we
        // should check if the image is in the viewport.
        // `event.data` is the event in this case.
        break;
      case 'start-loading':
        // The image is in the viewport so the image will start loading
        break;
      case 'mount-image':
        // The image has been loaded successfully so lets put it into the DOM
        break;
      case 'loading-succeeded':
        this.imageLoaded = true;
        this.imageLoadedDone.emit(true);
        this.cdref.detectChanges();
        // The image has successfully been loaded and placed into the DOM
        break;
      case 'loading-failed':
        // The image could not be loaded for some reason.
        // `event.data` is the error in this case
        break;
      case 'finally':
        // The last event before cleaning up
        break;
    }

  }

  getImageAspectRatio(): void {

    if (this.forceSquare) {
      this.imageAspectRatio = 1;
      return;
    }

    if (this.forceFourBySeven) {
      this.imageAspectRatio = 7 / 4;
      return;
    }

    if (this.imageData.width === 0 || this.imageData.height === 0) { // if something goes wrong (like svg)
      const img = new Image(); // try to load image and get its dimensions

      img.src = this.imageData.src;

      img.onload = (event) => {
        const loadedImage = event.currentTarget as HTMLImageElement;

        this.imageData.width = loadedImage.width;
        this.imageData.height = loadedImage.height;

        if (loadedImage.width === 0 || loadedImage.height === 0) { // if still no dimensions, than set some width for image and render it

          img.style.width = '10px';

          this.document.body.appendChild(img);

          this.imageAspectRatio = img.clientHeight / img.clientWidth; // get its sizes

          img.remove(); // and remove

        } else {
          this.imageAspectRatio = this.imageData.height / this.imageData.width;
        }

      };
    }

    this.imageAspectRatio = this.imageData.height / this.imageData.width;

  }


}
