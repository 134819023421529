import {gql} from 'apollo-angular';
import {ImageFragment} from './ImageFragment';
import {YoastSeoFragment} from './YoastSeoFragment';

export const TourFragment = gql`
  fragment tourFields on Tour {
    databaseId
    entityCode {
      codeAssignment {
        ... on Code {
          title
        }
      }
    }
    title
    acf {
      descriptionDe
      descriptionEn
      titleEn
      hiddenInArchive
      languageOverrideFile {
        mediaItemUrl
      }
      cards {
        ... on Tour_Acf_Cards_Object {
          object {
            ... on Object {
              ...objectFields
            }
          }
          overrideTitleDe
          overrideTitleEn
          timestampDe
          timestampEn
          durationDe
          durationEn
          overrideAudioDe {
            mediaItemUrl
          }
          overrideAudioEn  {
            mediaItemUrl
          }
          overrideWithResource
          resource {
            ... on Resource {
              databaseId
              acfResourceFields {
                audio {
                  transcriptDe
                  transcriptEn
                }
              }
            }
          }
        }
      }
      previewImages {
        ...imageFields
      }
      audioSpriteFileDe
      audioSpriteFileEn
    }
    seo {
      ...yoastSeoFields
    }
  }
  ${YoastSeoFragment}
  ${ImageFragment}
`;
